import React, { useState, useEffect } from "react";
import { useAppContext } from "../AppContext";

import ProgressSteps from "./pay/ProgressSteps";
import PayHeader from "./pay/PayHeader";
import BackButton from "./pay/BackButton";

import ChannelSelector from "./step-1/ChannelSelector";
import UPIPaymentComponent from "./step-2/UPIPayComponent";
import BankTransferPayComponent from "./step-2/BankTransferPayComponent";

import u from "../functions/utils.js";
import ConfirmationComponent from "./ConfirmationComponent.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";

export default function PayComponent() {
  const {
    setError,
    step,
    setStep,
    channels,
    setChannels,
    amount,
    channel,
    setChannel,
    resubmit,
    payment_data_init
  } = useAppContext();
  const {search}=useLocation();
  useEffect(() => {
    if (!u.isNonEmptyArray(channels) || !u.isPositiveNumber(amount))
      setError(true);
  }, [channels, step, channel]);

  const arrangeChannels = (_channels, upi_default) => {
    let upiIndex = -1;
    let bankIndex = -1;

    // Find the indices of "UPI" and "Bank Transfer" in the array
    for (let i = 0; i < _channels.length; i++) {
      if (_channels[i].type === "upi") {
        upiIndex = i;
      } else if (_channels[i].type === "bank_transfer") {
        bankIndex = i;
      }
    }

    if (upi_default) {
      // If upi_default is true, ensure "UPI" is the first element
      if (upiIndex !== -1 && upiIndex !== 0) {
        // Swap the "UPI" element with the first element
        [_channels[0], _channels[upiIndex]] = [
          _channels[upiIndex],
          _channels[0],
        ];
      }
    } else {
      if (bankIndex !== -1 && bankIndex !== 0) {
        [_channels[0], _channels[bankIndex]] = [
          _channels[bankIndex],
          _channels[0],
        ];
      }
    }

    return _channels;
  };

  const handleArrangeChannels = (upi_default) => {
    // Create a copy of the channels
    const newChannels = arrangeChannels([...channels], upi_default);

    // Update channels
    setChannels(newChannels);
    return newChannels;
  };

  //flag updated to useRef
  useEffect(() => {
    if (!payment_data_init.current) {
      payment_data_init.current=true;
      // Parse params
      const params = new URLSearchParams(search);
      const upi_default= params.get("upi_default")?u.parseParam(params.get("upi_default")):true;
      const updated_channels = handleArrangeChannels(upi_default);
      const skip_selection=params.get("skip_selection")?u.parseParam(params.get("skip_selection")):false;
      const skip_trivial_selection=params.get("skip_trivial_selection")?u.parseParam(params.get("skip_trivial_selection")):false;
      
      const deposit_types = channels.length;
      const channel_options = channels[0].options.length;

      if (resubmit) {
        setStep(2);
        setChannel(updated_channels[0].options[0]);
      } else if (
        (deposit_types === 1 &&
          channel_options === 1 &&
          skip_trivial_selection) ||
        skip_selection
      ) {
        setStep(2);
        setChannel(updated_channels[0].options[0]);
      }
    }
  }, []);

  try {
    const showStepName = () =>
      step === 1
        ? "Payment Options"
        : step === 2 && resubmit
        ? "Resubmit confirmation data"
        : step === 2
        ? "Make Payment and submit confirmation"
        : step === 3
        ? "Thank you"
        : "";

    return (
      <div className="bg-white sm:px-[4vw] sm:py-[2.4vw] bg-transparent flex justify-center">
        <div className="flex flex-col sm:p-2 bg-white rounded-[12px] sm:ring-1 sm:ring-gray-300 w-full lg:max-w-[720px] min-h-[0px] pb-[40px]">
          <BackButton />
          <PayHeader />
          <div className="p-[4vw] pt-8">
            <h3 className="text-xl text-center font-bold text-gray-900">
              {showStepName()}
            </h3>
            <ProgressSteps />
            {step === 1 && <ChannelSelector />}
            {(step === 2 && resubmit && <ConfirmationComponent />) ||
              (step === 2 && channel?.type === "upi" && (
                <UPIPaymentComponent />
              )) ||
              (step === 2 && channel?.type === "bank_transfer" && (
                <BankTransferPayComponent />
              ))}
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    setError(true);
    return;
  }
}
