// eslint-disable-next-line no-unused-vars
import nitro from "./nitro.js";
import u from "./utils.js";

export default async function loadPage({ app_id, auth_token, setPage, setError, setLoading, setChannels, setAmount, setStep }) {
    try {
        const { channel_types, amount } = await nitro.getChannels({
            setError,
            setLoading,
            app_id,
            auth_token,
        });

        if (!u.isNonEmptyArray(channel_types) || !u.isPositiveNumber(amount)) throw Error("Invalid channel_types or amount");
        setChannels(channel_types);
        setAmount(amount);
        setPage("pay");
    } catch (error) {
        console.log(error?.message || "error loading page");
        setError(true);
    } finally {
        setLoading(false);
    }
}
