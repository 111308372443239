// Relaxed version of our UTR validator
function isFutureDate(year, month, day) {
    const today = new Date();
    const dateToCheck = new Date(year, month - 1, day); 
    return dateToCheck > today;
}


function getDateFromJulian(year, julianDay) {
    const start = new Date(year, 0, 0);
    const julianDate = new Date(start.setDate(start.getDate() + julianDay));
    return julianDate;
}

// For RTGS
function isValidRTGSUTR(utr) {
    const rtgsRegex = /^[A-Z]{4}R[0-9A-Z](\d{4})(\d{2})(\d{2})\d{8}$/;
    const match = rtgsRegex.exec(utr);
    if (match) {
        const year = parseInt(match[1]);
        const month = parseInt(match[2]);
        const day = parseInt(match[3]);
        if (isFutureDate(year, month, day)) {
            return false;
        }
        return true;
    }
    return false;
}

// For NEFT
function isValidNEFTUTR(utr) {
    const neftRegex = /^[A-Z]{4}[0-9A-Z](\d{2})(\d{3})\d{6}$/;
    const match = neftRegex.exec(utr);
    if (match) {
        const year = 2000 + parseInt(match[1]); 
        const julianDay = parseInt(match[2]);
        const julianDate = getDateFromJulian(year, julianDay);
        const today = new Date();
        if (julianDate > today) {
            return false;
        }
        return true;
    }
    return false;
}

// For UPI
function isValidUPIUTR(utr) {
    const upiRegex = /^\d{12}$/;
    return upiRegex.test(utr);
}

// General case
export function isValidUTR(utr) {
    return isValidRTGSUTR(utr) || isValidNEFTUTR(utr) || isValidUPIUTR(utr);
}

// Test environment validator
export function testValidator(utr) {
    const testRegex = /^(test|TEST|fail|FAIL).{8,}$/;
    return testRegex.test(utr);
}
