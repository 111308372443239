import React from 'react';
import { useAppContext } from '../../AppContext';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export default function ResubmitLink() {
  const { setStep, setResubmit, channel, channels, setChannel } = useAppContext();

  const handleClick = () => {
    setStep(2);
    setResubmit(true);
    if(!channel){ // Logic fix for the resubmit link component
      setChannel(channels[0].options[0]);
    }
    
  };

  return (
    <div className="mt-4 flex items-center">
      <QuestionMarkCircleIcon className="h-10 w-10 text-gray-600 mr-4" />
      <a
        href="#"
        onClick={handleClick}
        className="text-gray-500 font-semibold italic"
      >
        Have you already made a payment?{' '}
        <span className="underline not-italic text-gray-700">Submit your UTR number</span>{' '}
        to complete the payment process.
      </a>
    </div>
  );
}
