import React from 'react'

function LoadingComponent() {
  return (
    <div className="flex justify-center items-center min-h-screen pb-[40vh]">
      <svg
        className="custom-spin -ml-1 mr-3 h-10 w-10"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#004B7c" strokeWidth="4"></circle>
        <path
          className="opacity-100"
          fill="#004B7c"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  )
}

export default LoadingComponent
