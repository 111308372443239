import React from "react";
import Turnstile, { useTurnstile } from "react-turnstile";
import { useAppContext } from "../AppContext";

export function TurnstileComponent() {
    const turnstile = useTurnstile();
    const { setTurnstileToken, setTurnstile } = useAppContext();
    return (
        <div className="flex justify-center items-center min-h-screen pb-[40vh]">
            <Turnstile
                sitekey="0x4AAAAAAAb560YWVpA3BxOb"
                theme="light"
                onVerify={(token) => {
                    setTurnstileToken(token);
                    setTurnstile(null);
                }}
            />
        </div>
    );
}
