/* eslint-disable react/prop-types */
import { DocumentDuplicateIcon } from "@heroicons/react/20/solid";
import QRCode from "qrcode.react";
import React, { useCallback } from "react";
import { useAppContext } from "../../AppContext";
import u from "../../functions/utils";
import ConfirmationComponent from "../ConfirmationComponent";

function UPIPaymentComponent() {
    let { channel, amount, setStep, addToast } = useAppContext();

    if (!u.isNonEmptyString(channel?.upi_address)) return;

    const address = channel.upi_address;
    const amtInr = (amount / 100).toFixed(2);
    const upiLink = `upi://pay?pa=${address}&am=${amtInr}&cu=INR`;

    const copyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(address);
        addToast("success", "Copied to clipboard!", 2400);
    }, [address]);

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex-col items-center justify-center w-full pb-4 sm:w-[80%]">
                <div className="flex flex-col items-center pb-4">
                    <QRCode value={upiLink} />
                    <span className="mt-2 text-sm text-gray-800">Scan to Pay</span>
                </div>

                <div
                    className={u.classNames(
                        "flex items-center px-[40px]", // added 'items-center'
                        "group cursor-pointer relative w-full rounded-md min-h-[48px] mb-4",
                        "bg-white",
                        "ring-1 ring-inset ring-gray-300",
                        " hover:ring-blu hover:ring-2 hover:bg-opacity-5 hover:bg-blu_med"
                    )}
                >
                    <div onClick={copyToClipboard} className="flex-grow flex p-4 justify-center no-outline">
                        <span className="text-sm sm:text-xl font-semibold text-gray-600 group-hover:text-blu">{address}</span>
                    </div>
                    <div className="flex items-center p-[1.5vw] absolute right-0">
                        <DocumentDuplicateIcon className="h-4 w-5 sm:w-5 sm:h-5 text-gray-400 group-hover:text-blu" />
                    </div>
                </div>

                <ConfirmationComponent></ConfirmationComponent>
            </div>
        </div>
    );
}

export default UPIPaymentComponent;
