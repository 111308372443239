import React, { useEffect } from 'react'
import { useAppContext } from '../../AppContext'


import ChannelTypeDropdown from './ChannelTypeDropdown'
import ChannelOptions from './ChannelOptions'

import channelTypeItem from './channel-type'
import ResubmitLink from './ResubmitLinkComponent'

export default function ChannelSelector() {
  let { channels } = useAppContext()
  
  let channel_type_options = channels.map((c) => channelTypeItem(c))

  return (
    <form className="space-y-8" action="#" method="POST">
      <div>
        <div className="mt-2">
          <ChannelTypeDropdown items={channel_type_options} label="Deposit Type" />
        </div>
        <ChannelOptions />
      </div>
      <ResubmitLink></ResubmitLink>
    </form>
  )
}
