import React, { useState } from 'react';

/* eslint-disable react/prop-types */
export default function HintCircle({ hintMessage }) {
    const [showHint, setShowHint] = useState(false);
  
    const handleHintClick = () => {
        setShowHint(true);
        setTimeout(() => {
            setShowHint(false);
        }, 5000);
    };
  
    return (
        <div className="relative">
            <div 
                onClick={handleHintClick} 
                className="w-6 h-6 rounded-full bg-gray-300 flex items-center justify-center cursor-pointer"
                title="Click for hint"
            >
                ?
            </div>
            {showHint && (
                <div 
                    className="absolute top-12 left-0 bg-white border border-gray-300 p-2 rounded shadow-lg z-50 whitespace-pre-line w-64 md:w-72 lg:w-80 xl:w-96"
                >
                    {hintMessage}
                </div>
            )}
        </div>
    );
}
/* eslint-enable react/prop-types */
